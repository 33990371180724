import $ from 'jquery';
window.jQuery = $;

import '../scss/index.scss';
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import LazyLoad from "vanilla-lazyload";

import { Fancybox } from "@fancyapps/ui";
Fancybox.bind("[data-fancybox]");
import 'slick-carousel'
import lottie from 'lottie-web';

import { disablePageScroll, enablePageScroll } from 'scroll-lock';

jQuery(document).ready(function ($) {

    // console.log(partytown);

    const lazyContent = new LazyLoad({
        use_native: true,
        elements_selector: '.block__img',
    });

    // var targetElement = $('header, .modal__content');
    const $scrollableElement = document.querySelectorAll('header, .modal__content');

    // gaEvents
    // gaEvents Header
    $('.header__nav a').click(function(e) {
        if($(this).closest("li").hasClass('login')) {
            tracking({
                event_name: 'login_attempt',
            });
        } else {
            tracking({
                event_name: 'navigation', 
                type: 'Header', 
                text: $(this).text().trim(), 
                target: $(this).attr('href')
            });
        }
    });

    $('.ginput_recaptcha').each(function(i){
        var thisID = $(this).attr('id');
        $(this).attr('id', thisID+'_'+i);
    });

    // gaEvents Footer
    $('.footer__menu a').click(function(e) {
        tracking({
            event_name: 'navigation', 
            type: 'Footer', 
            text: $(this).text().trim(), 
            target: $(this).attr('href')
        }); 
    });

    $('.footer__logo.marktplaats').click(function(e) {
        tracking({
            event_name: 'outbound_link_click', 
            name: 'Logo Marktplaats', 
        });
    });

    $('.footer__logo.last').click(function(e) {
        tracking({
            event_name: 'outbound_link_click', 
            name: 'Logo Adevinta', 
            target: $(this).attr('href')
        });
    });

    // gaEvents content clicks
    var page_type = $('body').data('tracking');

    $('.section a').click(function(e) {
        // e.preventDefault();
        if($(this).hasClass('breadcrumbs__item')) {
            tracking({
                event_name: 'navigation', 
                type: 'Breadcrumbs', 
                text: $(this).text().trim(), 
                target: $(this).attr('href')
            });
        } else if($(this).hasClass('contact')) {
            tracking({
                event_name: 'contact_click', 
                text: $(this).text().trim(), 
                type: 'Phone'
            });
        } else {
            var name = $(this).text().trim();
        
            if($(this).attr('name')) {
                name = $(this).attr('name');
            }
            console.log(name);
            var outbound = $(this).attr('target');
    
            if(page_type == 'Contact' && !outbound) {
                if(name.indexOf('chat') > -1) {
                    tracking({
                        event_name: 'contact_click', 
                        text: $(this).text().trim(), 
                        type: 'Chat'
                    });
                } else if(name.indexOf('00') > -1) {
                    tracking({
                        event_name: 'contact_click', 
                        text: $(this).text().trim(), 
                        type: 'Phone'
                    });
                } else if(name.indexOf('@') > -1) {
                    tracking({
                        event_name: 'contact_click', 
                        text: $(this).text().trim(), 
                        type: 'Email'
                    });
                }
            } else {
                if(outbound) {
                    tracking({
                        event_name: 'outbound_link_click', 
                        name: name, 
                        target: $(this).attr('href')
                    });
                } else {
                    tracking({
                        event_name: 'content_click', 
                        name: name, 
                        target: $(this).attr('href')
                    });
                }
            }
        }
    });

    $('data-accordion-header').each(function( accordionHeader) {
        console.log(accordionHeader);
    });

    // Mobile dropdown
    $('.header__toggle').click(function(e) {
        e.preventDefault();
        $('.header--menu').toggleClass('mobile');
        $('.header--content').toggleClass('mobile');

        if($('.header--content').hasClass('mobile')) {
            disablePageScroll($scrollableElement);
        } else {
            enablePageScroll($scrollableElement);
        }
    });

    $('[href="#open-chat"]').on("click", function(e) {
        e.preventDefault();

        if($('.chat').hasClass('open')) {
            $(this).toggleClass('active');
            $(this).text('Start chat');
        } else {
            $(this).toggleClass('active');
            $(this).text('Stop chat');
        }
        $('.chat').toggleClass('open');
    });

    var modal = false;

    if(window.location.hash == '#gf_20') {
        $('div').closest('.modal--form').toggleClass('open');
        disablePageScroll($scrollableElement);
    }

    $('[href="#popup"]').on("click", function(e) {
        e.preventDefault();
        disablePageScroll($scrollableElement);
        modal = $(this).data('popup');
        if(modal) {
            $('#' + modal).toggleClass('open');
        } else {
            $('div').closest('.modal--form').toggleClass('open');
        }
    });

    $('.modal--form .modal__close').on("click", function(e) {
        e.preventDefault();
        $('.modal--form').removeClass('open');
        enablePageScroll($scrollableElement);
    });

    // Mobile dropdown
    $('.menu-item-has-children .arrow').click(function(e) {
        e.preventDefault();
        $(this).parent().toggleClass('open');
    });
    
    // Sliders
    $('.slider--cards').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        rows: 0,
        centerMode: true,
        prevArrow: $('.slider--nav-cards .prev'),
        nextArrow: $('.slider--nav-cards .next'),
        responsive: [
            {
              breakpoint: 720,
              settings: {
                slidesToShow: 1,
              }
            }
        ]
    });

    $('.slider--steps').each(function(num, elem) {
        $(elem).slick({
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            rows: 0,
            centerMode: false,
            prevArrow: $(elem).closest('section').find('.slider--nav-steps .prev'),
            nextArrow: $(elem).closest('section').find('.slider--nav-steps .next'),
            responsive: [
                {
                    breakpoint: 720,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    });

    $('.slider--articles').slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        rows: 0,
        centerMode: false,
        prevArrow: $('.slider--nav-articles .prev'),
        nextArrow: $('.slider--nav-articles .next'),
        responsive: [
            {
                breakpoint: 920,
                settings: {
                  slidesToShow: 2.2,
                }
            },
            {
              breakpoint: 720,
              settings: {
                slidesToShow: 1.2,
              }
            }
        ]
    });

    var windowsize = $(window).width();
    if (windowsize < 820) {
        $('.slider--videos').slick({
            infinite: false,
            slidesToShow: 1.3,
            slidesToScroll: 1,
            arrows: true,
            rows: 0,
            centerMode: false,
            prevArrow: $('.slider--nav-videos .prev'),
            nextArrow: $('.slider--nav-videos .next'),
        });
    }

    // FAQ
    $('.block--faq .block__item button').click(function(e) {
        if($(this).attr('aria-expanded') == 'true') {
            $(this).attr('aria-expanded', false);
        } else {
            $(this).attr('aria-expanded', true);
        }
        // e.preventDefault();
        // var item = $(this).attr('id');
        // $(this).closest('.block__item').toggleClass('open');
        tracking({event_name: 'faq_click', text: $(this).text().trim()});
    });

    // Partners
    $('.partner-modal').click(function(e) {
        e.preventDefault();
        var partner = $(this).attr("data-partner");
        $('.modal[data-partner="' + partner + '"]').toggleClass('open');
        disablePageScroll($scrollableElement);
    });

    $('.modal__close').click(function(e) {
        e.preventDefault();
        $('.modal').removeClass('open');
        enablePageScroll($scrollableElement);
    });

    $(document).on('keyup', function(e) {
        if($('.modal.open')[0]) {
            if (e.key == "Escape") {
                $('.modal').removeClass('open');
                enablePageScroll($scrollableElement);
            }
        }
    });

    // Modal Prices
    $('.block--card-special.prices .icon.info').click(function(e) {
        e.preventDefault();
        $(this).closest('li').find('.modal--small').addClass('open');
    });

    $('block--card-special.prices .modal__close').click(function(e) {
        e.preventDefault();
        $('.modal--small').removeClass('open');
    });

    // Step by Step
    $('.step .block__link').click(function(e) {
        e.preventDefault();
        if($(this).closest('.step').hasClass('open')) {
            $(this).find('span').text('Toon artikelen');
        } else {
            $(this).find('span').text('Verberg artikelen');
        }
        $(this).closest('.step').toggleClass('open');
    });
    
    // Header local scroll
    $('.header--local ul li a, .button.scroll, .block--glossary ul li a, .block--articles-links ul li a').on('click', function(e) {
        e.preventDefault();
        $('.header--local ul li a').removeClass('active');
        $(this).addClass('active');
        var href = $(this).attr('href');
        var hash = href.substr(href.indexOf('#'));
        
        var windowsize = $(window).width();
        if (windowsize > 1215) {
            var offset = 61;
        } else {
            var offset = 100;
        }
        var location = $(hash).offset().top - offset;
        $('html, body').animate({scrollTop:location}, 500);
    }); 

    // Scroll tot top
    $(".back-to-top__button").click(function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });

    // Click event for any anchor tag that's href starts with #
    $('.scroll').click(function(event) {
        event.preventDefault();
        var id = $(this).attr("href");
        var windowsize = $(window).width();
        if (windowsize > 1215) {
            var offset = 10;
        } else {
            var offset = 70;
        }
        var location = $(id).offset().top - offset;
        $('html, body').animate({scrollTop:location}, 500);
    });


    // Subjects list mobile
    $('.block--subjects .block__title').click(function(event) {
        var windowsize = $(window).width();
        if (windowsize < 820) {
            event.preventDefault();
            $('.block--subjects').toggleClass('open');
        }
    });

    // Search filter
    $('.search-filter').click(function(event) {
        // event.preventDefault();
        $('.search-filter').removeClass('active');
        $(this).addClass('active');
    });

    // Advice sidebar
    $('.sidebar__toggle').click(function(event) {
        event.preventDefault();
        $(this).closest('li').toggleClass('open');
    });
});

// Lottie
document.querySelectorAll('.block__lottie').forEach(function(animation) {
    lottie.loadAnimation({
        container: animation,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: animation.dataset.file
    });
});

// Tracking function
function tracking({
    event_name,
    type,
    text,
    target,
    name
}) {
    window.dataLayer = window.dataLayer || [];
    if(event_name == 'navigation') {
        console.log('navigation');
        window.dataLayer.push({
            event: 'gaEvent',
            event_name: event_name,
            navigation: {
                type: type,
                text: text,
                target: target,
            }
        });
    } else if(event_name == 'content_click') {
        console.log('content_click');
        window.dataLayer.push({
            event: 'gaEvent',
            event_name: event_name,
            content_click: {
                name: name,
                target: target,
            }
        });  
    } else if(event_name == 'contact_click') {
        console.log('contact_click');
        window.dataLayer.push({
            event: 'gaEvent',
            event_name: event_name,
            contact_click: {
                text: text,
                type: type,
            }
        });                  
    } else if(event_name == 'outbound_link_click')  {
        console.log('outbound_link_click');
        window.dataLayer.push({
            event: 'gaEvent',
            event_name: event_name,
            outbound_link_click: {
                name: name,
                target: target,
            }
        });          
    } else if(event_name == 'file_download')  {
        console.log('file_download');
        window.dataLayer.push({
            event: 'gaEvent',
            event_name: event_name,
            file_download: {
                name: name,
                type: type,
            }
        });  
    } else if(event_name == 'error_tracking')  {
        console.log('error_tracking');
        window.dataLayer.push({
            event: 'gaEvent',
            event_name: event_name,
            error_tracking: {
                type: type,
                text: text,
            }
        });                
    } else if(event_name == 'faq_click') {
        console.log('faq_click');
        window.dataLayer.push({
            event: 'gaEvent',
            event_name: event_name,
            faq_click: {
                text: text,
            }
        });
    } else if(event_name == 'contact_click') {
        console.log('contact_click');
        window.dataLayer.push({
            event: 'gaEvent',
            event_name: event_name,
            contact_click: {
                text: text,
                type: type,
            }
        });          
    } else if(event_name == 'login_attempt') {
        console.log('login_attempt');
        window.dataLayer.push({
            event: 'gaEvent',
            event_name: event_name,
        });          
    }
}

jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};